import axios from 'axios'

// config
import baseUrl from '../config'

export const createAgreementTemplate = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/sef/agreement-templates`,
    data
  })

  return response.data
}

export const createBudgetCategory = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/sef/budget-categories`,
    data
  })

  return response.data
}

export const createOutputArea = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/sef/output-areas`,
    data
  })

  return response.data
}

export const createOutputIndicator = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/sef/output-indicators`,
    data
  })

  return response.data
}

export const createPartner = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/sef/partners`,
    data
  })

  return response.data
}

export const createPartnerDistrict = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/sef/partner-districts`,
    data
  })

  return response.data
}

export const createPurchaseRequest = async data => {
  return await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/sef/purchase-requests`,
    data,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
}

export const createSupplier = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/sef/suppliers`,
    data
  })

  return response.data
}

export const createWorkspace = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/sef/workspaces`,
    data
  })

  return response.data
}

export const createWorksite = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/sef/worksites`,
    data
  })

  return response.data
}

export const deleteBudget = async ({ budgetId }) => {
  return await axios.delete(`${baseUrl}/sef/budgets/${budgetId}`, { withCredentials: true })
}

export const deleteBudgetCategory = async ({ budgetCategoryId }) => {
  return await axios.delete(`${baseUrl}/sef/budget-categories/${budgetCategoryId}`, {
    withCredentials: true
  })
}

export const deleteOutput = async ({ outputId }) => {
  return await axios.delete(`${baseUrl}/sef/indicator-outcomes/${outputId}`, {
    withCredentials: true
  })
}

export const deleteOutputArea = async ({ outputAreaId }) => {
  return await axios.delete(`${baseUrl}/sef/output-areas/${outputAreaId}`, {
    withCredentials: true
  })
}

export const deleteOutputIndicator = async ({ outputIndicatorId }) => {
  return await axios.delete(`${baseUrl}/sef/output-indicators/${outputIndicatorId}`, {
    withCredentials: true
  })
}

export const deletePartnerDistrict = async ({ partnerDistrictId }) => {
  return await axios.delete(`${baseUrl}/sef/partner-districts/${partnerDistrictId}`, {
    withCredentials: true
  })
}

export const deletePurchaseRequest = async ({ purchaseRequestId }) => {
  return await axios.delete(`${baseUrl}/sef/purchase-requests/${purchaseRequestId}`, {
    withCredentials: true
  })
}

export const deleteSupplier = async ({ supplierId }) => {
  return await axios.delete(`${baseUrl}/sef/suppliers/${supplierId}`, { withCredentials: true })
}

export const deleteWorkspace = async ({ workspaceId }) => {
  return await axios.delete(`${baseUrl}/sef/workspaces/${workspaceId}`, { withCredentials: true })
}

export const deleteWorksite = async ({ worksiteId }) => {
  return await axios.delete(`${baseUrl}/sef/worksites/${worksiteId}`, { withCredentials: true })
}

export const fetchAgreementTemplate = async id => {
  const response = await axios.get(`${baseUrl}/sef/agreement-templates/${id}`, {
    withCredentials: true
  })
  return response.data
}

// TEMP HACK
import cleanPayload from 'utils/cleanPayload'

export const fetchAdmins = async payload => {
  const cleanedPayload = cleanPayload(payload)

  const response = await axios.get(`${baseUrl}/people`, {
    withCredentials: true,
    params: { ...cleanedPayload }
  })

  return response.data

  // const response = await axios.get(`${baseUrl}/sef/admin-users`, {
  //   withCredentials: true,
  //   params
  // })

  // return response.data
}

export const fetchAgreementTemplates = async params => {
  const response = await axios.get(`${baseUrl}/sef/agreement-templates`, {
    withCredentials: true,
    params
  })

  return response.data
}

export const fetchBudgetCategories = async params => {
  const response = await axios.get(`${baseUrl}/sef/budget-categories`, {
    withCredentials: true,
    params
  })

  return response.data
}

export const fetchBudgetCategory = async id => {
  const response = await axios.get(`${baseUrl}/sef/budget-categories/${id}`, {
    withCredentials: true
  })
  return response.data
}

export const fetchDistricts = async params => {
  const response = await axios.get(`${baseUrl}/sef/districts`, { withCredentials: true, params })

  return response.data
}

export const fetchDistrictsForOnboarding = async params => {
  const response = await axios.get(`${baseUrl}/sef/districts-for-onboarding`, {
    withCredentials: true,
    params
  })

  return response.data
}

export const fetchMunicipalities = async params => {
  const response = await axios.get(`${baseUrl}/sef/municipalities`, {
    withCredentials: true,
    params
  })

  return response.data
}

export const fetchOnboardingQuestions = async () => {
  const response = await axios.get(`${baseUrl}/sef/onboarding-questions`)

  return response.data
}

export const fetchOutputArea = async id => {
  const response = await axios.get(`${baseUrl}/sef/output-areas/${id}`, { withCredentials: true })
  return response.data
}

export const fetchOutput = async params => {
  const response = await axios.get(`${baseUrl}/sef/indicator-outcomes/${params.id}`, {
    withCredentials: true,
    params
  })

  return response.data
}

export const fetchOutputs = async params => {
  const response = await axios.get(`${baseUrl}/sef/indicator-outcomes`, {
    withCredentials: true,
    params
  })

  return response.data
}

export const fetchOutputAreas = async params => {
  const response = await axios.get(`${baseUrl}/sef/output-areas`, { withCredentials: true, params })

  return response.data
}

export const fetchOutputIndicator = async id => {
  const response = await axios.get(`${baseUrl}/sef/output-indicators/${id}`, {
    withCredentials: true
  })
  return response.data
}

export const fetchOutputIndicators = async params => {
  const response = await axios.get(`${baseUrl}/sef/output-indicators`, {
    withCredentials: true,
    params
  })

  return response.data
}

export const fetchOwnProfileDetails = async id => {
  const response = await axios.get(`${baseUrl}/sef/profile/${id}`, { withCredentials: true })

  return response.data
}

export const fetchPartnerDistrict = async ({ id, ...params }) => {
  const response = await axios.get(`${baseUrl}/sef/partner-districts/${id}`, {
    params,
    withCredentials: true
  })
  return response.data
}

export const fetchPartnerDistricts = async params => {
  const response = await axios.get(`${baseUrl}/sef/partner-districts`, {
    withCredentials: true,
    params
  })

  return response.data
}

export const fetchPartners = async params => {
  const response = await axios.get(`${baseUrl}/sef/partners`, { withCredentials: true, params })

  return response.data
}

export const fetchPayrollHistory = async params => {
  const response = await axios.get(`${baseUrl}/sef/payroll`, { withCredentials: true, params })

  return response.data
}

export const fetchPayrollPaymentRequest = async id => {
  const response = await axios.get(`${baseUrl}/sef/payroll/${id}`, { withCredentials: true })

  return response.data
}

export const updatePayrollRequest = async data => {
  if (data.status === 'Executed') {
    const response = await axios({
      withCredentials: true,
      method: 'post',
      url: `${baseUrl}/sef/payroll-executions`,
      data
    })

    return response.data
  }

  const response = await axios({
    withCredentials: true,
    method: 'PATCH',
    url: `${baseUrl}/sef/payroll/${data.paymentRequestId}`,
    data
  })

  return response.data
}

export const fetchPlace = async id => {
  const response = await axios.get(`${baseUrl}/sef/places/${id}`, { withCredentials: true })
  return response.data
}

export const fetchPlaces = async params => {
  const response = await axios.get(`${baseUrl}/sef/places`, { withCredentials: true, params })

  return response.data
}

export const fetchPurchaseRequest = async id => {
  const response = await axios.get(`${baseUrl}/sef/purchase-requests/${id}`, {
    withCredentials: true
  })
  return response.data
}

export const fetchPurchaseRequests = async params => {
  const response = await axios.get(`${baseUrl}/sef/purchase-requests`, {
    withCredentials: true,
    params
  })

  return response.data
}

export const fetchStats = async params => {
  const response = await axios.get(`${baseUrl}/sef/stats`, { params, withCredentials: true })

  return response.data
}

export const fetchSupplier = async id => {
  const response = await axios.get(`${baseUrl}/sef/suppliers/${id}`, { withCredentials: true })
  return response.data
}

export const fetchSuppliers = async params => {
  const response = await axios.get(`${baseUrl}/sef/suppliers`, { withCredentials: true, params })

  return response.data
}

export const fetchTimekeepers = async params => {
  const response = await axios.get(`${baseUrl}/sef/timekeepers`, { withCredentials: true, params })

  return response.data
}

export const fetchWards = async params => {
  const response = await axios.get(`${baseUrl}/sef/wards`, { withCredentials: true, params })

  return response.data
}

export const fetchWorkspace = async id => {
  const response = await axios.get(`${baseUrl}/sef/workspaces/${id}`, { withCredentials: true })
  return response.data
}

export const fetchWorksite = async ({ id, ...params }) => {
  const response = await axios.get(`${baseUrl}/sef/worksites/${id}`, {
    params,
    withCredentials: true
  })
  return response.data
}

export const fetchEmployeeDetails = async ({ employeeId, worksiteId }) => {
  const response = await axios.get(
    `${baseUrl}/sef/worksites/${worksiteId}/employee/${employeeId}`,
    { withCredentials: true }
  )

  return response.data
}

export const fetchWorkspaces = async params => {
  const response = await axios.get(`${baseUrl}/sef/workspaces`, { withCredentials: true, params })

  return response.data
}

export const fetchWorksites = async params => {
  const response = await axios.get(`${baseUrl}/sef/worksites`, { withCredentials: true, params })

  return response.data
}

export const importParticipants = async data => {
  return await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/sef/import-participants`,
    data
  })
}

export const promoteParticipants = async data => {
  return await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/sef/promote-participants`,
    data
  })
}

export const fetchPayrollBalance = async params => {
  const response = await axios.get(`${baseUrl}/sef/payroll-balance`, {
    withCredentials: true,
    params
  })

  return response.data
}

export const fetchPayrollPaymentRequests = async params => {
  const response = await axios.get(`${baseUrl}/sef/payroll-requests`, {
    withCredentials: true,
    params
  })

  return response.data
}

export const initiatePayrollRequest = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/sef/batch-payroll-requests`,
    data
  })

  return response.data
}

export const createPayfile = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/sef/payfiles`,
    data
  })

  return response.data
}

export const submitIndicatorOutcomes = async data => {
  return await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/sef/indicator-outcomes`,
    data,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
}

export const updateAgreementTemplate = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/sef/agreement-templates/${data.id}`,
    data
  })

  return response.data
}

export const updateOutput = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/sef/indicator-outcomes/${data.get('id')}`,
    data,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })

  return response.data
}

export const updateBudgetCategory = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/sef/budget-categories/${data.budgetCategoryId || data.id}`,
    data
  })

  return response.data
}

export const updateOutputArea = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/sef/output-areas/${data.id}`,
    data
  })

  return response.data
}

export const updateOutputIndicator = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/sef/output-indicators/${data.id}`,
    data
  })

  return response.data
}

export const updatePartnerDistrict = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/sef/partner-districts/${data.partnerDistrictId || data.id}`,
    data
  })

  return response.data
}

export const updatePlace = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/sef/places/${data.placeId || data.id}`,
    data
  })

  return response.data
}

export const updatePurchaseRequest = async purchaseRequest => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/sef/purchase-requests/${purchaseRequest.get('id')}`,
    data: purchaseRequest,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })

  return response.data
}

export const updateSupplier = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/sef/suppliers/${data.supplierId || data.id}`,
    data
  })

  return response.data
}

export const updateWorkspace = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/sef/workspaces/${data.workspaceId || data.id}`,
    data
  })

  return response.data
}

export const updateWorksite = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/sef/worksites/${data.worksiteId || data.id}`,
    data
  })

  return response.data
}

export const onboardEmployee = async data => {
  return await axios({
    method: 'put',
    url: `${baseUrl}/sef/partner-districts/onboard-employee`,
    data
  })
}

export const sendOutcomesTrackingReminder = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/sef/indicator-outcomes-reminder`,
    data
  })

  return response.data
}

export const requestAgreementZip = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/sef/generate-employment-agreement-zip-file`,
    data,
    responseType: 'blob'
  })

  // Create a link element to download the file
  const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', 'agreements.zip') // Set the download filename
  document.body.appendChild(link)
  link.click()

  // Cleanup
  link.parentNode.removeChild(link)

  return response.data
}

export const toggleSefBioFeature = async id => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/sef/toggle-bio-feature/${id}`
  })

  return response.data
}

export const fetchEmploymentAgreement = async id => {
  const response = await axios.get(`${baseUrl}/sef/agreements/${id}`, { withCredentials: true })
  return response.data
}

export const updateEmploymentAgreement = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/sef/employment-agreement/${data.get('participantId')}`,
    data,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })

  return response.data
}

export const downloadBulkAgreements = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/sef/download-bulk-agreements`,
    data,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })

  return response.data
}
