import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { navigate, Redirect } from '@reach/router'
import { parse } from 'qs'

// redux
import * as authModule from '../../redux/modules/authentication'
import * as notificationsModule from '../../redux/modules/notifications'
import * as peopleModule from '../../redux/modules/people'

// styles
import {
  ContentContainer,
  DashboardContainer,
  DashboardOverWrapper,
  DashboardWrapper,
  DashLayoutContentContainer,
  SiteDownNotice
} from './styles'

// config
import l, { isSef, isSefEmployee, siteName } from 'config/localization'
import constants from 'config/constants'

// utils
import isLoggedIn from 'utils/auth'
import {
  getDashSidebarCollapseStatus,
  toggleDashSidebarCollapseStatus,
  getApiStatus
} from 'utils/localStorageManager'

// load ModalsCanvas and Navbar synchronously to avoid scroll bug
// where Y scroll position has to be > 0 to show modal
import DashboardSidebar from 'components/DashboardSidebar'
import EmailConfirmedBanner from 'components/EmailConfirmedBanner'
import EmailConfirmationBanner from 'components/EmailConfirmationBanner'
import ModalsCanvas from 'components/Modals/ModalsCanvas'
import Navbar from 'components/Navbar'

import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'

// keyboard key codes
const ESCAPE_KEYCODE = 27
const ONE_KEYCODE = 49
const TWO_KEYCODE = 50
const THREE_KEYCODE = 51
const FOUR_KEYCODE = 52
const FIVE_KEYCODE = 53
const SIX_KEYCODE = 54

const determineContentAccess = props => {
  const { onlyGodRole, hasGodRole, isLoading, hasSuperAdminRole, superOrGodRole } = props

  if (onlyGodRole && !hasGodRole && !isLoading) return true
  if (superOrGodRole && !hasSuperAdminRole && !hasGodRole && !isLoading) return true

  return false
}

const shouldShowFormBar = ({ locationInfo }) => {
  const { success } = parse(location.search.replace('?', ''))
  const isSuccess = success === 'true'

  // Do not show FormBar for success messages
  if (isSuccess) return false

  // Only these paths are FormBar ready
  if (locationInfo.newOrgGoalPath) return true
  if (locationInfo.newStoryPath) return true
  if (locationInfo.newFeedbackPath) return true
  if (locationInfo.newOppPath) return true
  if (locationInfo.newChallengePath) return true
  if (locationInfo.newOrgPath) return true
  if (locationInfo.peopleNewActive && isSef) return true

  // Do not show FormBar
  return false
}

const shouldShowNavBar = ({ locationInfo }) => {
  const { success } = parse(location.search.replace('?', ''))
  const isSuccess = success === 'true'

  // Force show NavBar for success messages
  if (isSuccess) return true

  // Hide NavBar for these paths
  if (locationInfo.newOrgGoalPath) return false
  if (locationInfo.newFeedbackPath) return false
  if (locationInfo.newStoryPath) return false
  if (locationInfo.newChallengePath) return false
  if (locationInfo.newOppPath) return false
  if (locationInfo.newOrgPath) return false
  if (locationInfo.peopleNewActive && isSef) return false

  // Show NavBar
  return true
}

const getLocationInfo = ({ location }) => {
  const path = location.pathname
  const search = location.search

  const homeActive = path.match(/\/dashboard$/)
  const emailActivityActive = path.includes('/dashboard/email-activity')
  const singleEntityActive = path.match(/\/dashboard\/\w+\/\d/)
  const opportunitiesActive = path.includes(`/dashboard/${l('ROUTE_CAUSES')}`)
  const campaignsActive = path.includes(`/dashboard/${l('ROUTE_CAMPS')}`)
  const activeStoriesPath = path.includes(`/dashboard/stories`)
  const mnosPath = path.includes(`/dashboard/mnos`)
  const zeroRatedWebsitesPath = path.includes(`/dashboard/skipdata-requests`)
  const activeLikesPath = path.includes(`/dashboard/likes`)
  const activeContributionsPath = path.includes(`/dashboard/contributions`)
  const activeChallengesPath = path.includes(`/dashboard/challenges`)
  const activePersonalMembershipsPath = path.includes(`/dashboard/memberhips`)
  const organisationsActive = path.includes(`/dashboard/${l('ROUTE_ORGS')}`)
  const scholarshipsActive = path.includes('/dashboard/scholarships')
  const sponsorshipsActive = path.includes('/dashboard/sponsorships')
  const peopleActive = path.match(/dashboard\/people/)
  const participantsActive = path.match(/dashboard\/participants/)
  const adminsActive = path.match(/dashboard\/admins/)
  const peopleNewActive = path.match(/dashboard\/people\/new/)
  const squadsActive = path.match(/dashboard\/squads/)
  const teamsActive = path.match(/dashboard\/teams/)
  const pbosActive = path.match(/dashboard\/pbos/)
  const districtsActive = path.match(/dashboard\/districts/)
  const partnersActive = path.match(/dashboard\/partners/)
  const agreementsActive = path.match(/dashboard\/agreements/)
  const rulesActive = path.match(/dashboard\/rules/)
  const workspacesActive = path.match(/dashboard\/workspaces/)
  const budgetCategoriesActive = path.match(/dashboard\/budget-categories/)
  const attendanceActive = path.match(/dashboard\/attendance/)
  const outputsActive = path.match(/dashboard\/outputs/)
  const outputAreasActive = path.match(/dashboard\/output-areas/)
  const outputIndicatorsActive = path.match(/dashboard\/output-indicators/)
  const worksitesActive = path.match(/dashboard\/worksites/)
  const wardsActive = path.match(/dashboard\/wards/)
  const munisActive = path.match(/dashboard\/municipalities/)
  const suppliersActive = path.match(/dashboard\/suppliers/)
  const sirApplicationsActive = path.match(/dashboard\/sir-applications/)
  const payrollActive = path.match(/dashboard\/payroll/)
  const purchaseRequestsActive = path.match(/dashboard\/purchase-requests/)
  const emailActivityPathActive = path.match(/dashboard\/email-activity/)
  const platformEventsPathActive = path.match(/dashboard\/platform-events/)
  const billingActive = path.match(/dashboard\/billing/)
  const accountActive = path.match(/dashboard\/account/)
  const boostsActive = path.match(/dashboard\/boosts/)
  const rsvpsActive = path.match(/dashboard\/rsvps/)
  const enrolmentsActive = path.match(/dashboard\/enrolments/)
  const registrationsActive = path.match(/dashboard\/registrations/)
  const newStoryPath = path.match(/dashboard\/stories\/new/)
  const newFeedbackPath = path.match(/dashboard\/feedback\/new/)
  const newOppPath = path.includes(`/dashboard/${l('ROUTE_CAUSES')}/new`)
  const newChallengePath = path.includes(`/dashboard/challenges/new`)
  const newOrgPath = path.includes(`/dashboard/${l('ROUTE_ORGS')}/new`)
  const newOrgGoalPath =
    path.includes(`/dashboard/${l('ROUTE_ORGS')}`) && path.includes('goals/new')

  return {
    accountActive,
    activeStoriesPath,
    pbosActive,
    zeroRatedWebsitesPath,
    mnosPath,
    activeContributionsPath,
    activeLikesPath,
    activePersonalMembershipsPath,
    activeChallengesPath,
    boostsActive,
    billingActive,
    enrolmentsActive,
    emailActivityActive,
    homeActive,
    opportunitiesActive,
    campaignsActive,
    organisationsActive,
    scholarshipsActive,
    sponsorshipsActive,
    peopleActive,
    participantsActive,
    adminsActive,
    peopleNewActive,
    rsvpsActive,
    singleEntityActive,
    teamsActive,
    squadsActive,
    payrollActive,
    purchaseRequestsActive,
    sirApplicationsActive,
    suppliersActive,
    districtsActive,
    partnersActive,
    budgetCategoriesActive,
    outputAreasActive,
    outputsActive,
    attendanceActive,
    outputIndicatorsActive,
    worksitesActive,
    wardsActive,
    munisActive,
    platformEventsPathActive,
    emailActivityPathActive,
    registrationsActive,
    newStoryPath,
    newFeedbackPath,
    newOppPath,
    newChallengePath,
    newOrgPath,
    newOrgGoalPath,
    search,
    workspacesActive,
    rulesActive,
    agreementsActive
  }
}

const scrollToPlace = () => {
  const menu = document.getElementById('dashboard-menu-items-wrapper')
  const activeItem = document.getElementById('active-dash-nav-item')
  if (!menu || !activeItem) return true

  // 30 = 15px padding on the sides + 15px to show snippet of previous nav item
  const scrollOffset = activeItem.offsetLeft - 30

  menu.scroll({ left: scrollOffset })
}

const deriveMaxWidth = ({ location }) => {
  const { pathname } = location
  const fullWidthPaths = ['/dashboard/enrolments']
  if (fullWidthPaths.includes(pathname)) return '100%'

  return '1500px'
}

class DashboardLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dashboardSettingsVisible: false,
      discoverDropdownVisible: false,
      pricingDropdownVisible: false,
      storiesDropdownVisible: false,
      scrollY: 0,
      visibleModal: null
    }

    this.escFunction = this.escFunction.bind(this)
    this.closeAllModals = this.closeAllModals.bind(this)
    this.toggleMenuModal = this.toggleMenuModal.bind(this)
    this.toggleDiscoverDropdown = this.toggleDiscoverDropdown.bind(this)
    this.togglePricingDropdown = this.togglePricingDropdown.bind(this)
  }

  closeAllModals() {
    this.setState({ visibleModal: null })
  }

  toggleMenuModal(value) {
    const { visibleModal } = this.state
    const forceClose = value === 'close'
    this.setState({ visibleModal: visibleModal ? null : forceClose ? null : 'menu-modal' })
  }

  togglePricingDropdown() {
    this.setState({ pricingDropdownVisible: !this.state.pricingDropdownVisible })
  }

  toggleDiscoverDropdown() {
    this.setState({ discoverDropdownVisible: !this.state.discoverDropdownVisible })
  }

  toggleDashSidebarSettings() {
    this.setState({ dashboardSettingsVisible: !this.state.dashboardSettingsVisible })
  }

  toggleSidebarCollapse() {
    this.forceUpdate()
    toggleDashSidebarCollapseStatus()
  }

  escFunction(event) {
    const loggedIn = isLoggedIn()

    // some keyboard shortcuts aren't for SEF
    const bpOnlyFeature = !isSef && !isSefEmployee

    // determine if there's any open modal
    const anyModalIsOpen = this.state.visibleModal !== null
    const menuModalIsOpen = this.state.visibleModal === 'menu-modal'

    // Any modal that is currently open will be closed
    if (anyModalIsOpen && event.keyCode === ESCAPE_KEYCODE) {
      // first close modals
      this.closeAllModals()

      // then scroll to previous Y position
      window.scrollTo({ top: this.state.scrollY })

      return true
    }

    // No modal is open so escape should open the menu modal
    if (event.keyCode === ESCAPE_KEYCODE) {
      this.setState({ scrollY: window.scrollY, visibleModal: 'menu-modal' })
    }

    if (menuModalIsOpen && loggedIn && event.keyCode === ONE_KEYCODE) {
      // Keyboard shortcut for key '1'
      this.setState({ visibleModal: null })
      navigate(`/dashboard`)
    }

    if (bpOnlyFeature && menuModalIsOpen && loggedIn && event.keyCode === TWO_KEYCODE) {
      // Keyboard shortcut for key '2'
      this.setState({ visibleModal: null })

      const { userData } = this.props
      const profilePath = userData.username ? `/@${userData.username}` : `/users/${userData.id}`

      navigate(profilePath)
    }

    if (bpOnlyFeature && menuModalIsOpen && loggedIn && event.keyCode === THREE_KEYCODE) {
      // Keyboard shortcut for key '3'
      this.setState({ visibleModal: null })
      navigate('/search/challenges')
    }

    if (bpOnlyFeature && menuModalIsOpen && event.keyCode === FOUR_KEYCODE) {
      // Keyboard shortcut for key '4'
      this.setState({ visibleModal: null })
      navigate(`/search/${l('ROUTE_OPPS')}`)
    }

    if (bpOnlyFeature && menuModalIsOpen && event.keyCode === FIVE_KEYCODE) {
      // Keyboard shortcut for key '5'
      this.setState({ visibleModal: null })
      navigate(`/search/nonprofits`)
    }

    if (bpOnlyFeature && menuModalIsOpen && event.keyCode === SIX_KEYCODE) {
      // Keyboard shortcut for key '6'
      this.setState({ visibleModal: null })
      navigate(`/search/companies`)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false)

    const { autologin } = parse(location.search.replace('?', ''))

    setTimeout(() => {
      scrollToPlace()
    }, 500)

    if (isLoggedIn()) {
      this.props.fetchMyRoles()
      this.props.fetchNotifications()
    } else {
      this.props.attemptAutoLogin({ signInToken: autologin })
    }
  }

  render() {
    const {
      dashboardSettingsVisible,
      discoverDropdownVisible,
      pricingDropdownVisible,
      storiesDropdownVisible,
      visibleModal
    } = this.state
    const {
      anythingIsSubmitting,
      anythingIsLoading,
      hasFetchedRoles,
      isLoading,
      location,
      notifications,
      onlyGodRole,
      superOrGodRole,
      roleData = [],
      userData = {},
      logout,
      children,
      requestConfirmationEmail,
      workspaceDetails
    } = this.props

    // need a better way to check if site is down
    const apiStatus = getApiStatus()
    const siteIsDown = apiStatus === 'down'

    const sidebarIsCollapsed = getDashSidebarCollapseStatus() === 'true'
    const { emailConfirmed, autologin, downBypass } = parse(location.search.replace('?', ''))
    const loggedIn = isLoggedIn()
    const locationInfo = getLocationInfo({ location })
    const isUsingDownBypass = downBypass === 'true'

    if (siteIsDown && !isUsingDownBypass) {
      return <SiteDownNotice />
    }

    const isSefSite = isSef || isSefEmployee
    if (!loggedIn && isSefSite) {
      return <Redirect to={`/login?action=sefLogin`} noThrow />
    }

    if (!loggedIn && !autologin) {
      const redirectPath = `/login?redirectTo=${location.href}`
      return <Redirect to={redirectPath} noThrow />
    }

    const menuModalIsOpen = visibleModal === 'menu-modal'
    const emailJustConfirmed = emailConfirmed === 'true'

    const hasGodRole = roleData.some(role => role.role_type === constants.ROLES.GOD_MODE)
    const hasSuperAdminRole = roleData.some(role => role.role_type === constants.ROLES.SUPERADMIN)
    const emailNeedsConfirming = !emailJustConfirmed && userData.email && !userData.email_confirmed
    const maxWidth = deriveMaxWidth({ location })

    const cannotViewContent = determineContentAccess({
      onlyGodRole,
      hasGodRole,
      isLoading,
      hasSuperAdminRole,
      superOrGodRole
    })

    if (cannotViewContent) {
      return (
        <DashboardWrapper>
          <DashboardSidebar
            hasFetchedRoles={hasFetchedRoles}
            locationInfo={locationInfo}
            logout={logout}
            notifications={notifications}
            roles={roleData}
            user={userData}
            workspaceDetails={workspaceDetails}
          />

          <DashboardContainer>
            <ContentContainer>You are not permitted to view this content.</ContentContainer>
          </DashboardContainer>
        </DashboardWrapper>
      )
    }

    const displayBpNavbar = shouldShowNavBar({ locationInfo })
    const displayFormBar = shouldShowFormBar({ locationInfo })

    if (dashboardSettingsVisible) {
      return (
        <DashboardOverWrapper visibleModal={visibleModal}>
          <DashboardWrapper isHomePage={locationInfo.homeActive}>
            <ConditionalDisplay displayWhen={[displayBpNavbar]}>
              <Navbar
                isDashboardPage
                isLoading={anythingIsLoading || anythingIsSubmitting}
                isLoggedIn
                discoverDropdownVisible={discoverDropdownVisible}
                menuModalIsOpen={menuModalIsOpen}
                pricingDropdownVisible={pricingDropdownVisible}
                roleData={roleData}
                storiesDropdownVisible={storiesDropdownVisible}
                toggleDiscoverDropdown={this.toggleDiscoverDropdown}
                toggleMenuModal={this.toggleMenuModal}
                togglePricingDropdown={this.togglePricingDropdown}
                userData={userData}
              />
            </ConditionalDisplay>

            <Helmet title={siteName} />

            <DashboardSidebar
              dashboardSettingsVisible
              displayBpNavbar={displayBpNavbar}
              displayFormBar={displayFormBar}
              hasFetchedRoles={hasFetchedRoles}
              locationInfo={locationInfo}
              logout={logout}
              toggleDashSidebarSettings={() => this.toggleDashSidebarSettings()}
              roles={roleData}
              updateUser={this.props.updateUser}
              user={userData}
              workspaceDetails={workspaceDetails}
            />
          </DashboardWrapper>
        </DashboardOverWrapper>
      )
    }

    return (
      <DashboardOverWrapper visibleModal={visibleModal}>
        <DashboardWrapper isHomePage={locationInfo.homeActive}>
          <ConditionalDisplay displayWhen={[displayBpNavbar]}>
            <Navbar
              isDashboardPage
              isLoading={anythingIsLoading || anythingIsSubmitting}
              isLoggedIn
              discoverDropdownVisible={discoverDropdownVisible}
              menuModalIsOpen={menuModalIsOpen}
              notifications={notifications}
              pricingDropdownVisible={pricingDropdownVisible}
              roleData={roleData}
              storiesDropdownVisible={storiesDropdownVisible}
              toggleDiscoverDropdown={this.toggleDiscoverDropdown}
              toggleMenuModal={this.toggleMenuModal}
              togglePricingDropdown={this.togglePricingDropdown}
              userData={userData}
            />
          </ConditionalDisplay>

          <Helmet title={siteName} />

          <DashboardContainer
            displayFormBar={displayFormBar}
            isHomePage={locationInfo.homeActive}
            sidebarIsCollapsed={sidebarIsCollapsed}
          >
            <DashLayoutContentContainer
              displayFormBar={displayFormBar}
              emailNeedsConfirming={emailNeedsConfirming}
              maxWidth={maxWidth}
              topPad={emailNeedsConfirming}
            >
              <ConditionalDisplay displayWhen={[emailNeedsConfirming]}>
                <EmailConfirmationBanner
                  displayFormBar={displayFormBar}
                  maxWidth={locationInfo.homeActive ? '1000px' : '100%'}
                  requestConfirmationEmail={() => requestConfirmationEmail(userData)}
                />
              </ConditionalDisplay>

              <ConditionalDisplay displayWhen={[emailJustConfirmed]}>
                <EmailConfirmedBanner maxWidth={locationInfo.homeActive ? '1000px' : '100%'} />
              </ConditionalDisplay>

              {children}
            </DashLayoutContentContainer>
          </DashboardContainer>

          <DashboardSidebar
            displayBpNavbar={displayBpNavbar}
            displayFormBar={displayFormBar}
            hasFetchedRoles={hasFetchedRoles}
            locationInfo={locationInfo}
            logout={logout}
            roles={roleData}
            sidebarIsCollapsed={sidebarIsCollapsed}
            toggleSidebarCollapse={() => this.toggleSidebarCollapse()}
            toggleDashSidebarSettings={() => this.toggleDashSidebarSettings()}
            user={userData}
            workspaceDetails={workspaceDetails}
          />
        </DashboardWrapper>

        <ModalsCanvas
          closeAllModals={this.closeAllModals}
          loggedIn={loggedIn}
          logout={logout}
          userData={userData}
          visibleModal={visibleModal}
        />
      </DashboardOverWrapper>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  fetchNotifications: () => dispatch(notificationsModule.fetchNotifications()),
  fetchMyRoles: () => dispatch(authModule.fetchMyRoles()),
  attemptAutoLogin: key => dispatch(authModule.attemptAutoLogin(key)),
  requestConfirmationEmail: userEmail => dispatch(authModule.requestConfirmationEmail(userEmail)),
  logout: () => dispatch(authModule.logout()),
  updateUser: payload => dispatch(peopleModule.updateUser(payload))
})

const mapStateToProps = state => ({
  roleData: state.authentication.roleData || [],
  userData: state.authentication.userData,
  workspaceDetails: state.authentication.workspaceDetails,
  hasFetchedRoles: state.authentication.hasFetchedRoles,
  notifications: state.notifications.data,
  anythingIsLoading:
    state.authentication.isLoading ||
    state.applications.isLoading ||
    state.campaigns.isLoading ||
    state.opportunities.isLoading ||
    state.organisations.isLoading ||
    state.stories.isLoading ||
    state.people.isLoading,
  anythingIsSubmitting:
    state.authentication.isSubmitting ||
    state.applications.isSubmitting ||
    state.campaigns.isSubmitting ||
    state.opportunities.isSubmitting ||
    state.stories.isSubmitting ||
    state.organisations.isSubmitting ||
    state.people.isSubmitting
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout)
